import {
  minimalZoom,
  minimalZoomPublicTransport,
  minimalZoomSigns,
  translations,
} from "../config";

function mapCoordinatesToString(map) {
  return (
    "lat1=" +
    map.lat1 +
    "&long1=" +
    map.long1 +
    "&lat2=" +
    map.lat2 +
    "&long2=" +
    map.long2
  );
}

function typesToString(selectedTypes, identifierString) {
  return selectedTypes.reduce((finalString, type) => {
    return finalString + "&" + identifierString + "=" + type;
  }, "");
}

export function fetchSigns(map, selectedSigns) {
  let url = process.env.REACT_APP_BACKEND_URL + "signs/?";
  const coordinates = mapCoordinatesToString(map);
  const types = typesToString(selectedSigns, "category");

  return fetch(url + coordinates + types)
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
}

export function fetchEvents(map, selectedEvents) {
  let url = process.env.REACT_APP_BACKEND_URL + "events/waze/?";
  const coordinates = mapCoordinatesToString(map);
  const types = typesToString(selectedEvents, "type");

  return fetch(url + coordinates + types)
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
}

export function fetchPublicTransport(map, selectedPublicTransport) {
  let url = process.env.REACT_APP_BACKEND_URL + "events/traffic/?";
  const coordinates = mapCoordinatesToString(map);
  const types = typesToString(selectedPublicTransport, "type");

  return fetch(url + coordinates + types)
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
}

export async function getEvents(mapPosition, selectedEventsTypes) {
  if (mapPosition.zoom < minimalZoom || selectedEventsTypes.length === 0) {
    return [];
  } else {
    return fetchEvents(mapPosition, selectedEventsTypes);
  }
}

export async function getSigns(mapPosition, selectedSignsTypes) {
  if (mapPosition.zoom < minimalZoomSigns || selectedSignsTypes.length === 0) {
    return [];
  } else {
    return await fetchSigns(mapPosition, selectedSignsTypes);
  }
}

export async function getPublicTransport(mapPosition, selectedSignsTypes) {
  if (
    mapPosition.zoom < minimalZoomPublicTransport ||
    selectedSignsTypes.length === 0
  ) {
    return [];
  } else {
    return await fetchPublicTransport(mapPosition, selectedSignsTypes);
  }
}

export function getTranslation(key) {
  const translation = translations[key];
  if (!translation) return key;
  return translation;
}
