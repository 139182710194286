import { Icon } from "leaflet";
import road_closed from "../icons/road_closed.png";
import warning from "../icons/error.png";
import jam from "../icons/jam.png";
import police from "../icons/police.png";
import weather from "../icons/weather.png";
import accident from "../icons/accident.png";
import bus from "../icons/bus.png";
import tram from "../icons/tram.png";
import train from "../icons/train.png";
import trolleybus from "../icons/trolleybus.png";

export function createIcon(type) {
  return new Icon({
    iconUrl: getIcon(type),
    iconSize: [50, 50],
    iconAnchor: [25, 25],
  });
}

export function getIcon(type) {
  switch (type) {
    case "ROAD_CLOSED":
      return road_closed;
    case "ACCIDENT":
      return accident;
    case "JAM":
      return jam;
    case "POLICE":
      return police;
    case "HAZARD":
      return warning;
    case "WEATHERHAZARD":
      return weather;
    case "OTHER":
      return warning;
    case "BUS":
      return bus;
    case "TRAIN":
      return train;
    case "TRAM":
      return tram;
    case "TROLLEY":
      return trolleybus;
    default:
      return warning;
  }
}
