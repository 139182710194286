export const defaultMapPosition = {
  lat1: 48.440890263962956,
  long1: 18.562676659756132,
  lat2: 48.39041688194314,
  long2: 18.719660988979765,
  zoom: 14,
};

export const defaultCenterMap = [48.34936, 17.219755];

export const eventTypes = [
  "JAM",
  "POLICE",
  "HAZARD",
  "WEATHERHAZARD",
  "ACCIDENT",
  "CONSTRUCTION",
  "ROAD_CLOSED",
  "OTHER",
];

export const signsTypes = ["WARN", "REG", "INFO", "ADD", "OTHR"];

export const publicTransportTypes = ["TRAM", "BUS", "TRAIN", "TROLLEY"];

export const translations = {
  JAM: "zápcha",
  POLICE: "hliadka",
  HAZARD: "nebezpečenstvo",
  WEATHERHAZARD: "počasie",
  ACCIDENT: "nehoda",
  CONSTRUCTION: "práca na ceste",
  ROAD_CLOSED: "uzávera cesty",
  OTHER: "iné",
  WARN: "výstražné",
  REG: "regulačné",
  INFO: "informačné",
  ADD: "dodatkové",
  OTHR: "informatívne, prevádzkové, smerové a iné značky",
  BUS: "autobusy",
  TRAM: "elektičky",
  TROLLEY: "trolejbusy",
  TRAIN: "vlaky",
};

export const minimalZoom = 8;

export const minimalZoomPublicTransport = 10;

export const minimalZoomSigns = 14;
export const webSocketURL = process.env.REACT_APP_WEBSOCKET_URL;
export const weatherApiKey = "4c751c988ceb1548afb4885765dac3e7";

export const temperatureColors = [
  { label: "-40°C", value: "rgba(130, 22, 146, 1)" },
  { label: "-30°C", value: "rgba(130, 87, 219, 1)" },
  { label: "-20°C", value: "rgba(32, 140, 236, 1)" },
  { label: "-10°C", value: "rgba(32, 196, 232, 1)" },
  { label: "0°C", value: "rgba(35, 221, 221, 1)" },
  { label: "10°C", value: "rgba(194, 255, 40, 1)" },
  { label: "20°C", value: "rgba(255, 240, 40, 1)" },
  { label: "25°C", value: "rgba(255, 194, 40,1)" },
  { label: "30°C", value: "rgba(252, 128, 20, 1)" },
];

export const pressureColors = [
  { label: "94 kPa", value: "rgba(0,115,255,1)" },
  { label: "96 kPa", value: "rgba(0,170,255,1)" },
  { label: "98 kPa", value: "rgba(75,208,214,1)" },
  { label: "100 kPa", value: "rgba(141,231,199,1)" },
  { label: "101 kPa", value: "rgba(176,247,32,1)" },
  { label: "102 kPa", value: "rgba(240,184,0,1)" },
  { label: "104 kPa", value: "rgba(251,85,21,1)" },
  { label: "106 kPa", value: "rgba(243,54,59,1)" },
  { label: "108 kPa", value: "rgba(198,0,0,1)" },
];

export const cloudsColors = [
  { label: "0%", value: "rgba(255, 255, 255, 0.0)" },
  { label: "10%", value: "rgba(253, 253, 255, 0.1)" },
  { label: "20%", value: "rgba(252, 251, 255, 0.2)" },
  { label: "30%", value: "rgba(250, 250, 255, 0.3)" },
  { label: "40%", value: "rgba(249, 248, 255, 0.4)" },
  { label: "50%", value: "rgba(247, 247, 255, 0.5)" },
  { label: "60%", value: "rgba(246, 245, 255, 0.75)" },
  { label: "70%", value: "rgba(244, 244, 255, 1)" },
  { label: "80%", value: "rgba(243, 242, 255, 1)" },
  { label: "90%", value: "rgba(242, 241, 255, 1)" },
  { label: "100%", value: "rgba(240, 240, 255, 1)" },
];

export const rainColors = [
  { label: "0 mm", value: "rgba(225, 200, 100, 0)" },
  { label: "0.1 mm", value: "rgba(200, 150, 150, 0)" },
  { label: "0.2 mm", value: "rgba(150, 150, 170, 0)" },
  { label: "0.5 mm", value: "rgba(120, 120, 190, 0)" },
  { label: "1 mm", value: "rgba(110, 110, 205, 0.3)" },
  { label: "10 mm", value: "rgba(80,80, 225, 0.7)" },
  { label: "140 mm", value: "rgba(20, 20, 255, 0.9)" },
];
