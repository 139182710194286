import { Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { signsMap } from "./SignsMapper";

export default function SignMarker(props) {
  const { marker } = props;

  const nameOfFile = signsMap[marker["sign_type_id"]] || "101.svg";

  return (
    <Marker
      position={[marker.location.lat, marker.location.long]}
      icon={
        new Icon({
          iconUrl: require("../signs_icons/" + nameOfFile),
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })
      }
    >
      <Popup>
        {
          <div>
            <img
              src={require("../signs_icons/" + nameOfFile)}
              alt={marker["sign_type_desc"]}
              style={{ width: 200, height: 200 }}
            />
            <h4>{marker.sign_type_desc}</h4>
          </div>
        }
      </Popup>
    </Marker>
  );
}
