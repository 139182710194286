import { Marker, Popup } from "react-leaflet";
import { createIcon, getIcon } from "./Icons";

export default function PublicTransportMarker(props) {
  const { vehicle } = props;

  return (
    <Marker
      icon={createIcon(vehicle.type)}
      position={[vehicle.location.lat, vehicle.location.long]}
    >
      <Popup>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "50px", height: "50px", marginRight: "5px" }}
              src={getIcon(vehicle.type)}
            />
            <div>
              <h2 style={{ marginBottom: "5px" }}>
                {vehicle.type === "TRAIN" ? vehicle.name : vehicle.line}
              </h2>
              <p style={{ marginTop: "0px" }}>{vehicle.shipper}</p>
            </div>
          </div>
          {vehicle.type !== "TRAIN" && <h3>{vehicle.name}</h3>}
          <h3>{vehicle.description}</h3>
          <p>
            {vehicle.delayText !== ""
              ? vehicle.delayText
              : "Meškanie: " + vehicle.delay + " min"}
          </p>
          <p style={{ color: "red" }}>{vehicle.note}</p>
        </div>
      </Popup>
    </Marker>
  );
}
