import { useMapEvents } from "react-leaflet";

export default function MapEventsHandler({ setMapPosition, setActiveLayer }) {
  const map = useMapEvents({
    moveend: () => {
      setMapPosition({
        lat1: map.getBounds().getNorthWest().lat,
        long1: map.getBounds().getNorthWest().lng,
        lat2: map.getBounds().getSouthEast().lat,
        long2: map.getBounds().getSouthEast().lng,
        zoom: map.getZoom(),
      });
    },
    overlayadd: (e) => {
      setActiveLayer(e.name);
    },
    overlayremove: (e) => {
      setActiveLayer(null);
    },
  });
  return null;
}
