import { signsTypes } from "../config";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { getTranslation } from "../services/Services";

export default function SignsFilter(props) {
  const { selectedSignsTypes, setSelectedSignsTypesCallback } = props;

  function handleChange(event) {
    if (event.target.checked) {
      setSelectedSignsTypesCallback(
        selectedSignsTypes.concat(event.target.value)
      );
    } else {
      setSelectedSignsTypesCallback(
        selectedSignsTypes.filter((obj) => obj !== event.target.value)
      );
    }
  }

  function handleChangeParent(event) {
    if (event.target.checked) {
      setSelectedSignsTypesCallback(signsTypes);
    } else {
      setSelectedSignsTypesCallback([]);
    }
  }

  return (
    <div style={{ paddingLeft: "20px" }}>
      <FormControlLabel
        label="Značky"
        componentsProps={{ typography: { fontWeight: "bold" } }}
        control={
          <Checkbox
            checked={selectedSignsTypes.length === signsTypes.length}
            indeterminate={selectedSignsTypes.length > 0}
            onChange={handleChangeParent}
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {signsTypes.map((type) => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                checked={selectedSignsTypes.includes(type)}
                value={type}
                onChange={handleChange}
              />
            }
            label={getTranslation(type)}
          />
        ))}
      </Box>
    </div>
  );
}
