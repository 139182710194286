import React, { useState } from "react";
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import { createIcon } from "./Icons";
import MapEventsHandler from "./MapEventsHandler";
import { defaultCenterMap, defaultMapPosition, weatherApiKey } from "../config";
import SignMarker from "./SignMarker";
import { Icon } from "leaflet";
import car from "../icons/car.png";
import PublicTransportMarker from "./PublicTransportMarker";
import Legend from "./Legend";

export default function Map(props) {
  const { events, signs, cars, publicTransport, setMapPositionCallback } =
    props;

  const [activeLayer, setActiveLayer] = useState(null);

  const carIcon = new Icon({
    iconUrl: car,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
  });

  return (
    <MapContainer
      center={defaultCenterMap}
      zoom={defaultMapPosition.zoom}
      scrollWheelZoom={true}
      style={{
        width: "100%",
        height: "100vh",
      }}
      // whenCreated={setMap}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Events icons:  <a href="https://icons8.com">https://icons8.com</a> '
        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
      />
      <LayersControl>
        <LayersControl.Overlay
          name="Teplota vzduchu"
          checked={activeLayer === "Teplota vzduchu"}
        >
          <TileLayer
            attribution='&copy; Weather data provided by <a href="https://openweathermap.org/">OpenWeather</a>'
            url={
              "http://maps.openweathermap.org/maps/2.0/weather/TA2/{z}/{x}/{y}?fill_bound=true&appid=" +
              weatherApiKey
            }
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay
          name="Oblačnosť"
          checked={activeLayer === "Oblačnosť"}
        >
          <TileLayer
            attribution='&copy; Weather data provided by <a href="https://openweathermap.org/">OpenWeather</a>'
            url={
              "http://maps.openweathermap.org/maps/2.0/weather/CL/{z}/{x}/{y}?appid=" +
              weatherApiKey
            }
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay
          name="Atmosferický tlak"
          checked={activeLayer === "Atmosferický tlak"}
        >
          <TileLayer
            attribution='&copy; Weather data provided by <a href="https://openweathermap.org/">OpenWeather</a>'
            url={
              "http://maps.openweathermap.org/maps/2.0/weather/APM/{z}/{x}/{y}?appid=" +
              weatherApiKey
            }
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Zrážky" checked={activeLayer === "Zrážky"}>
          <TileLayer
            attribution='&copy; Weather data provided by <a href="https://openweathermap.org/">OpenWeather</a>'
            url={
              "http://maps.openweathermap.org/maps/2.0/weather/PA0/{z}/{x}/{y}?appid=" +
              weatherApiKey
            }
          />
        </LayersControl.Overlay>
      </LayersControl>

      {signs.length > 0 &&
        signs.map((marker, id) => <SignMarker key={id} marker={marker} />)}
      {events.length > 0 &&
        events.map((event, id) => (
          <Marker
            key={id}
            position={[event.location.lat, event.location.long]}
            icon={createIcon(event.type)}
          >
            <Popup>
              {
                <div>
                  <h2>{getEventTranslation(event.type)}</h2>
                  <h3>{event.description}</h3>
                  <h3>
                    Poloha: {event.location.lat} {event.location.long}
                  </h3>
                </div>
              }
            </Popup>
          </Marker>
        ))}
      {cars.length > 0 &&
        cars.map((car) => (
          <Marker
            key={car.id}
            position={[car.position.lat, car.position.lon]}
            icon={carIcon}
          >
            <Popup>
              {
                <div style={{ width: "200px" }}>
                  <h2>{"Vozidlo " + car.id}</h2>
                  <h3>Rýchlosť: {car.speed}</h3>
                  <h3>
                    Poloha: {car.position.lat} {car.position.lon}
                  </h3>
                </div>
              }
            </Popup>
          </Marker>
        ))}
      {publicTransport.length > 0 &&
        publicTransport.map((vehicle, id) => (
          <PublicTransportMarker key={id} vehicle={vehicle} />
        ))}
      <MapEventsHandler
        setMapPosition={setMapPositionCallback}
        setActiveLayer={setActiveLayer}
      />
      <Legend activeLayer={activeLayer} />
    </MapContainer>
  );
}

export function getEventTranslation(type) {
  switch (type) {
    case "ROAD_CLOSED":
      return "Uzávera cesty";
    case "ACCIDENT":
      return "Nehoda";
    case "JAM":
      return "Zápcha";
    case "POLICE":
      return "Policajná hliadka";
    case "HAZARD":
      return "Nebezpečenstvo";
    case "WEATHERHAZARD":
      return "Počasie";
    case "OTHER":
      return "Iné";
    default:
      return type;
  }
}
