import EventsFilter from "./EventsFilter";
import { minimalZoom, minimalZoomSigns } from "../config";
import { Alert } from "@mui/material";
import SignsFilter from "./SignsFilter";
import CarsController from "./CarsController";
import PublicTransportFilter from "./PublicTransportFilter";

export default function SideBar(props) {
  const {
    zoom,
    eventsProps,
    signsProps,
    setCarsCallback,
    publicTransportProps,
  } = props;
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ padding: "16px" }}>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "0px" }}
        >
          <h1 style={{ marginBottom: "0.5em" }}>Digital Twin Map</h1>
        </div>
        <hr style={{ marginBottom: "20px" }} />
        <h2 style={{ marginBottom: "0.5em" }}>Filter</h2>

        <EventsFilter {...eventsProps} />
        <SignsFilter {...signsProps} />
        <CarsController setCarsCallback={setCarsCallback} />
        <PublicTransportFilter {...publicTransportProps} />
      </div>
      <div>
        {zoom < minimalZoom && eventsProps.selectedEventTypes.length > 0 && (
          <Alert severity="error">
            Pre zobrazenie udalostí priblížte mapu.
          </Alert>
        )}
        {zoom < minimalZoomSigns &&
          signsProps.selectedSignsTypes.length > 0 && (
            <Alert severity="error">
              Pre zobrazenie značiek priblížte mapu.
            </Alert>
          )}
      </div>
    </div>
  );
}
