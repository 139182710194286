export const signsMap = {
  10100: "101.svg",
  11010: "110-10.svg",
  11020: "110-20.svg",
  11110: "111-10.svg",
  11120: "111-20.svg",
  11200: "112-10.svg",
  11300: "113-10.svg",
  11410: "114-10.svg",
  11420: "114-20.svg",
  11430: "114-30.svg",
  11500: "115.svg",
  11600: "116.svg",
  12000: "120.svg",
  12100: "121.svg",
  12200: "122.svg",
  12500: "125-10.svg",
  12600: "126.svg",
  13000: "130.svg",
  13100: "131.svg",
  13200: "132.svg",
  13500: "135.svg",
  13600: "136.svg",
  14000: "140-10.svg",
  14100: "141-10.svg",
  14200: "142-10.svg",
  14300: "143-10.svg",
  14500: "145-10.svg",
  14600: "146-10.svg",
  15100: "151.svg",
  15210: "152-10.svg",
  15211: "152-11.svg",
  15212: "152-12.svg",
  15220: "152-10.svg",
  15221: "152-21.svg",
  15222: "152-22.svg",
  15300: "153-50.svg",
  20100: "201.svg",
  20200: "202.svg",
  20300: "203.svg",
  21010: "210-10.svg",
  21020: "210-20.svg",
  21030: "210-30.svg",
  21031: "210-31.svg",
  21032: "210-32.svg",
  21033: "210-33.svg",
  21110: "211-10.svg",
  21120: "211-20.svg",
  21210: "212-10.svg",
  21220: "212-20.svg",
  21300: "213.svg",
  21510: "215-10.svg",
  21520: "215-20.svg",
  21600: "216.svg",
  22000: "220.svg",
  22100: "221.svg",
  22200: "222.svg",
  22300: "223-50.svg",
  22550: "225-50.svg",
  22560: "225-60.svg",
  22570: "225-70.svg",
  22580: "225-80.svg",
  23000: "230.svg",
  23100: "231.svg",
  23200: "232.svg",
  23300: "231.svg",
  23350: "233-50.svg",
  23351: "233-56.svg",
  23352: "233-57.svg",
  23355: "233-52.svg",
  23358: "233-53.svg",
  23362: "233-55.svg",
  23370: "233-58.svg",
  23371: "233-63.svg",
  23380: "233-60.svg",
  23385: "233-64.svg",
  23400: "234.svg",
  23500: "235.svg",
  24000: "240.svg",
  24100: "241-7.svg",
  24200: "242.svg",
  24300: "243.svg",
  24400: "244-7.svg",
  24500: "245-51.svg",
  24800: "248.svg",
  24900: "249.svg",
  25000: "250-30.svg",
  25100: "251.svg",
  25300: "253-30.svg",
  25400: "254.svg",
  25500: "255.svg",
  25600: "256-50.svg",
  26000: "260-30.svg",
  26100: "261.svg",
  26300: "263-30.svg",
  26400: "264.svg",
  26500: "265.svg",
  26700: "267.svg",
  26800: "268-20.svg",
  26900: "269-20.svg",
  27000: "270.svg",
  27100: "271.svg",
  27200: "272.svg",
  27250: "272-50.svg",
  27350: "273-50.svg",
  27360: "273-50.svg",
  27370: "273-50.svg",
  27380: "273-50.svg",
  27500: "275.svg",
  27600: "276.svg",
  27700: "277.svg",
  27800: "278.svg",
  28000: "280-50.svg",
  28100: "281-50.svg",
  28200: "282-50.svg",
  28300: "283-50.svg",
  30100: "301.svg",
  30120: "301-20.svg",
  30200: "302.svg",
  30300: "303.svg",
  30400: "304.svg",
  30500: "305.png",
  30561: "305.png",
  30600: "306.png",
  30661: "306.png",
  30700: "307.png",
  30800: "308.png",
  30900: "309.svg",
  31000: "310.svg",
  31100: "311.svg",
  31200: "312.svg",
  31300: "313.svg",
  31400: "314.svg",
  31500: "315.svg",
  31600: "316.svg",
  31700: "317.svg",
  31800: "318.svg",
  31900: "319.svg",
  32000: "320.svg",
  32120: "321-10.svg",
  32130: "321-30.svg",
  32200: "322-50.svg",
  32500: "325-10.svg",
  32600: "326-10.svg",
  32700: "327-50.svg",
  32800: "328.svg",
  33000: "330-50.svg",
  33050: "330-50.svg",
  33052: "330-52.svg",
  33054: "330-54.svg",
  33060: "330-60.svg",
  33061: "330-61.svg",
  33062: "330-62.svg",
  33063: "330-63.svg",
  33066: "330-66.svg",
  33067: "330-67.svg",
  33070: "330-70.svg",
  33071: "330-71.svg",
  33074: "330-74.svg",
  33082: "330-82.svg",
  33095: "330-95.svg",
  33150: "331-50.svg",
  33160: "331-60.svg",
  33200: "332.svg",
  33300: "333.svg",
  33400: "334.svg",
  33500: "335.svg",
  33600: "336-31.svg",
  34000: "340-60.svg",
  34050: "340-51.svg",
  34053: "340-52.svg",
  34100: "341.svg",
  34200: "342.png",
  34300: "343.png",
  34400: "344.png",
  35000: "350-51.svg",
  35100: "351.svg",
  35200: "352-50.svg",
  35300: "353-50.svg",
  35500: "355-60.svg",
  36000: "360.png",
  36100: "361.png",
  36200: "362.png",
  36300: "363.png",
  36400: "364.png",
  36500: "365.png",
  36600: "366.png",
  36700: "367.png",
  36800: "368.png",
  37000: "370.png",
  37100: "371.png",
  37200: "372.png",
  37300: "373.png",
  37400: "374.png",
  37600: "376.png",
  37700: "377.png",
  37800: "378.png",
  38000: "380.png",
  38100: "381.png",
  38200: "382.svg",
  38300: "383.svg",
  39000: "390-10.svg",
  39100: "391-10.svg",
  39700: "397-50.svg",
  40100: "401-30.svg",
  40200: "402-30.svg",
  40300: "403-31.svg",
  40400: "404-34.svg",
  40833: "428-11.png",
  40838: "428-11.png",
  41100: "411-10.svg",
  42100: "421-10.svg",
  43100: "431-10.svg",
  44100: "441-10.svg",
  44200: "442-11.svg",
  44300: "443-10.svg",
  44400: "444-11.svg",
  50100: "501-50.svg",
  50200: "502-50.svg",
  50300: "503-10.svg",
  50400: "504-10.svg",
  50500: "505-50.svg",
  50600: "506-50.svg",
  50683: "506-86.svg",
  50700: "507-50.svg",
  50800: "508-3,5.svg",
  50900: "509-50.svg",
  51000: "510-10.svg",
  51100: "511.svg",
  51200: "512.svg",
  51300: "513.svg",
  51400: "514.svg",
  51500: "515-51.svg",
  52000: "520.svg",
  52100: "521-50.svg",
  52200: "522.svg",
  52500: "525.svg",
  52600: "526.svg",
  52700: "527-3.svg",
  53000: "530.svg",
  53100: "531.svg",
  53200: "532-50.svg",
  53300: "533.svg",
  70500: "705.png",
  70600: "706.png",
  70700: "707.png",
  A6: "A6.png",
  A10: "A10.png",
  A24: "A24.png",
  A31: "A31.png",
  A33: "A33.png",
  B32a: "B_32a.png",
  B32b: "B_32b.png",
  B37: "B37.png",
  C5: "212-10.svg",
  C6c: "210-33.svg",
  C16: "C_16.png",
  C17: "C_17.png",
  C19: "C_19.png",
  C22c: "C22c.png",
  C25: "C_25.png",
  C27: "441-10.svg",
  C30: "C30.png",
  E1: "E1.png",
  E5b: "E5b.png",
  E8a: "E8a.png",
  E8b: "E8b.png",
  E8c: "E8c.png",
  E9: "E9.png",
  E11: "E11.png",
  E16a: "E16a.png",
  II19a: "II19a.png",
  II19b: "II19a.png",
  II20a: "II20b.png",
  II20b: "II20b.png",
  II20c: "II20b.png",
  II21a: "II21a.png",
  II21b: "II21b.png",
  IP1: "IP1.png",
  IP2: "IP2.png",
  IP5: "IP5.png",
  IP8: "IP8.png",
  IP10: "IP10.png",
  IP21b: "IP21b.png",
  IP30: "IP30.png",
  IP30a: "IP30a.png",
  IP31a: "IP31a.png",
  IP31b: "IP31a.png",
  IP31c: "IP31a.png",
  IP32: "IP32.png",
  IP33a: "IP33a.png",
  IS12: "IS12.png",
  IS13: "IS12.png",
  IS38: "IS38.png",
  IS40e: "IS40e.png",
  IS40i: "IS40i.png",
  IS40j: "IS40j.png",
  P3: "P3.png",
  P7: "P7.png",
  P12: "P12.png",
  P14: "P14.png",
  P15: "P15.png",
  Z2c: "Z2c.png",
};
