import "./App.css";
import React, { useEffect, useState } from "react";

import Map from "./components/Map";
import SideBar from "./components/SideBar";
import { defaultMapPosition } from "./config";
import { getEvents, getPublicTransport, getSigns } from "./services/Services";

function App(data) {
  const [signs, setSigns] = useState([]);
  const [events, setEvents] = useState([]);
  const [cars, setCars] = useState([]);
  const [publicTransport, setPublicTransport] = useState([]);
  const [mapPosition, setMapPosition] = useState(defaultMapPosition);
  const [selectedEventsTypes, setSelectedEventsTypes] = useState([]);
  const [selectedSignsTypes, setSelectedSignsTypes] = useState([]);
  const [selectedPublicTransportTypes, setSelectedPublicTransportTypes] =
    useState([]);

  useEffect(async () => {
    setEvents(await getEvents(mapPosition, selectedEventsTypes));
    setSigns(await getSigns(mapPosition, selectedSignsTypes));
    setPublicTransport(
      await getPublicTransport(mapPosition, selectedPublicTransportTypes)
    );
  }, [mapPosition]);

  useEffect(async () => {
    setEvents(await getEvents(mapPosition, selectedEventsTypes));
  }, [selectedEventsTypes]);

  useEffect(async () => {
    setSigns(await getSigns(mapPosition, selectedSignsTypes));
  }, [selectedSignsTypes]);

  useEffect(async () => {
    setPublicTransport(
      await getPublicTransport(mapPosition, selectedPublicTransportTypes)
    );
  }, [selectedPublicTransportTypes]);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ width: "20%", overflowY: "scroll" }}>
        <SideBar
          zoom={mapPosition.zoom}
          eventsProps={{
            selectedEventTypes: selectedEventsTypes,
            setSelectedEventsTypesCallback: setSelectedEventsTypes,
          }}
          signsProps={{
            selectedSignsTypes: selectedSignsTypes,
            setSelectedSignsTypesCallback: setSelectedSignsTypes,
          }}
          setCarsCallback={setCars}
          publicTransportProps={{
            selectedPublicTransportTypes: selectedPublicTransportTypes,
            setSelectedPublicTransportTypesCallback:
              setSelectedPublicTransportTypes,
          }}
        />
      </div>
      <div style={{ width: "80%" }}>
        <Map
          setMapPositionCallback={setMapPosition}
          signs={signs}
          events={events}
          cars={cars}
          publicTransport={publicTransport}
        />
      </div>
    </div>
  );
}

export default App;
