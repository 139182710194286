import { publicTransportTypes } from "../config";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { getTranslation } from "../services/Services";

export default function PublicTransportFilter(props) {
  const {
    selectedPublicTransportTypes,
    setSelectedPublicTransportTypesCallback,
  } = props;

  function handleChange(event) {
    if (event.target.checked) {
      setSelectedPublicTransportTypesCallback(
        selectedPublicTransportTypes.concat(event.target.value)
      );
    } else {
      setSelectedPublicTransportTypesCallback(
        selectedPublicTransportTypes.filter((obj) => obj !== event.target.value)
      );
    }
  }

  function handleChangeParent(event) {
    if (event.target.checked) {
      setSelectedPublicTransportTypesCallback(publicTransportTypes);
    } else {
      setSelectedPublicTransportTypesCallback([]);
    }
  }

  return (
    <div style={{ paddingLeft: "20px" }}>
      <FormControlLabel
        label="Verejná doprava"
        componentsProps={{ typography: { fontWeight: "bold" } }}
        control={
          <Checkbox
            checked={
              selectedPublicTransportTypes.length ===
              publicTransportTypes.length
            }
            indeterminate={selectedPublicTransportTypes.length > 0}
            onChange={handleChangeParent}
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {publicTransportTypes.map((type) => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                checked={selectedPublicTransportTypes.includes(type)}
                value={type}
                onChange={handleChange}
              />
            }
            label={getTranslation(type)}
          />
        ))}
      </Box>
    </div>
  );
}
