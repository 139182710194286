import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { webSocketURL } from "../config";
import useWebSocket from "react-use-websocket";

export default function CarsController(props) {
  const { setCarsCallback } = props;

  const [carsTurnedOn, setCarsTurnedOn] = useState(true);
  const [socketUrl, setSocketUrl] = useState(webSocketURL);

  // TODO - viac koser odpojenie od socketu
  // TODO - error ked sa nevie pripojit

  function processMessage(event) {
    const response = JSON.parse(event.data);
    console.log(response);
    if (response.type === "update_vehicles") {
      setCarsCallback(response.vehicles);
    }
  }

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    socketUrl,

    {
      onOpen: () => console.log("opened"),
      onClose: () => console.log("closed"),
      share: false,
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    lastMessage && processMessage(lastMessage);
  }, [lastMessage]);

  const readyStateString = {
    0: "CONNECTING",
    1: "OPEN",
    2: "CLOSING",
    3: "CLOSED",
  }[readyState];

  useEffect(() => {
    console.log(readyStateString);
  }, [readyState]);

  const handleChangeCheckbox = () => {
    if (carsTurnedOn) {
      setSocketUrl("");
      setCarsCallback([]);
      setCarsTurnedOn(false);
    } else {
      setSocketUrl(webSocketURL);
      setCarsTurnedOn(true);
    }
  };

  return (
    <FormControlLabel
      style={{ paddingLeft: "20px" }}
      label="Premávka"
      componentsProps={{ typography: { fontWeight: "bold" } }}
      control={
        <Checkbox checked={carsTurnedOn} onChange={handleChangeCheckbox} />
      }
    />
  );
}
