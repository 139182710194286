import {
  cloudsColors,
  pressureColors,
  rainColors,
  temperatureColors,
} from "../config";

function LegendContent({ colors }) {
  return (
    <div style={{ backgroundColor: "white", padding: "5px" }}>
      {colors.map((color) => (
        <div
          key={color.label}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              backgroundColor: color.value,
              width: "20px",
              height: "20px",
            }}
          />
          <div>{color.label}</div>
        </div>
      ))}
    </div>
  );
}

export default function Legend({ activeLayer }) {
  function getColors() {
    switch (activeLayer) {
      case "Teplota vzduchu":
        return temperatureColors;
      case "Atmosferický tlak":
        return pressureColors;
      case "Oblačnosť":
        return cloudsColors;
      case "Zrážky":
        return rainColors;
      default:
        return [];
    }
  }

  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control leaflet-bar">
        {activeLayer && <LegendContent colors={getColors()} />}
      </div>
    </div>
  );
}
