import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { eventTypes } from "../config";
import { getTranslation } from "../services/Services";

export default function EventsFilter(props) {
  const { selectedEventTypes, setSelectedEventsTypesCallback } = props;

  function handleChange(event) {
    if (event.target.checked) {
      setSelectedEventsTypesCallback(
        selectedEventTypes.concat(event.target.value)
      );
    } else {
      setSelectedEventsTypesCallback(
        selectedEventTypes.filter((obj) => obj !== event.target.value)
      );
    }
  }

  function handleChangeParent(event) {
    if (event.target.checked) {
      setSelectedEventsTypesCallback(eventTypes);
    } else {
      setSelectedEventsTypesCallback([]);
    }
  }

  return (
    <div style={{ paddingLeft: "20px" }}>
      <FormControlLabel
        label="Udalosti"
        componentsProps={{ typography: { fontWeight: "bold" } }}
        // sx={{ fontWeight: "bold" }}
        control={
          <Checkbox
            checked={selectedEventTypes.length === eventTypes.length}
            indeterminate={selectedEventTypes.length > 0}
            onChange={handleChangeParent}
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {eventTypes.map((type) => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                checked={selectedEventTypes.includes(type)}
                value={type}
                onChange={handleChange}
              />
            }
            label={getTranslation(type)}
          />
        ))}
      </Box>
    </div>
  );
}
